import React, { useEffect, useState } from "react";
import { Box, useTheme, IconButton, Badge } from "@mui/material";
import { useSelector } from "react-redux";
import { /*ColorModeContext,*/ themeMode } from "../theme";
// import InputBase from "@mui/material/InputBase";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
// import LocalizationButton from "../components/LocalizationButton";
import ProfileBarOnTapBar from "../components/ProfileBarOnTapBar";
import { useLocation, useNavigate } from "react-router-dom";
import * as signalR from "@microsoft/signalr";
import { counterfeitServices } from "services/counterfeitServices";
import usePermission from "Hooks/usePermission";
import allPermissions from "routes/allPermissions";

const Topbar = (props) => {
  const theme = useTheme();
  const colors = themeMode(theme.palette.mode);
  const location = useLocation();
  const navigate = useNavigate();
  const hasPermission = usePermission();

  const SHELL_ACF = process.env.REACT_APP_BASE_URL_ACF;
  const facilityTypeId = useSelector(
    (state) => state.user?.data?.facilityTypeId
  );
  const userData = useSelector((state) => state.user?.data);

  const [hasNotifications, setHasNotifications] = useState(false);
  const [activeAlarm, setActiveAlarm] = useState(0);
  const [connection, setConnection] = useState("");
  const ownerId = userData?.ownerId;

  useEffect(() => {
    let newConnection;
    if (facilityTypeId === 5) {
      newConnection = new signalR.HubConnectionBuilder()
        .withUrl(`${SHELL_ACF}/notificationHub`, {
          accessTokenFactory: () => userData?.token,
        })
        .build();

      newConnection.on("Notify", (count) => {
        setActiveAlarm(Number(count));
      });

      const startConnection = async () => {
        try {
          await newConnection.start();
          console.log("SignalR connected");
        } catch (error) {
          console.error("SignalR connection failed:", error);
        }
      };

      startConnection();

      setConnection(newConnection);
    }

    return () => {
      if (newConnection) {
        newConnection.stop();
      }
    };
  }, []);

  const resetCount = async () => {
    try {
      await counterfeitServices.resetAlarmNotification();
    } catch (error) {
      console.error(error);
    }
  };

  const handleNavigateToAlarmsNotifications = async () => {
    navigate("/counterfeit/alarm-notifications");
    resetCount();
  };
  function logout() {
    props.logout();
  }

  return (
    <Box
      display="flex"
      justifyContent="end"
      alignItems="center"
      className="Topbar"
      backgroundColor={colors.primary[400]}
      borderBottom={`1px solid ${colors.grey[800]}`}
    >
      <Box
        gap={1}
        display="flex"
        flexDirection="row-reverse"
        alignItems="center"
      >
        <Box display="flex" alignItems="center">
          <ProfileBarOnTapBar logout={logout} navigate={navigate} />
        </Box>
        {facilityTypeId === 5 &&
          hasPermission(allPermissions.AlarmNotificationViewing) && (
            <Box display="flex" alignItems="center">
              <IconButton
                onClick={handleNavigateToAlarmsNotifications}
                size="medium"
                color="primaryOwner.contrastText"
                sx={
                  location.pathname === "/counterfeit/alarm-notifications"
                    ? { backgroundColor: "primaryOwner.main" }
                    : { backgroundColor: "background.default" }
                }
              >
                <Badge
                  color="error"
                  badgeContent={activeAlarm > 99 ? "99+" : activeAlarm}
                >
                  <AccessAlarmIcon
                    sx={
                      ownerId === 1 &&
                      location.pathname === "/counterfeit/alarm-notifications"
                        ? { color: "primaryOwner.contrastText" }
                        : { color: "neutral.dark" }
                    }
                  />
                </Badge>
              </IconButton>
            </Box>
          )}

        {facilityTypeId === 5 && (
          <Box display="flex" alignItems="center">
            <IconButton
              onClick={() => navigate("/counterfeit/notifications")}
              size="medium"
              color="primaryOwner.contrastText"
              sx={
                location.pathname === "/counterfeit/notifications"
                  ? { backgroundColor: "primaryOwner.main" }
                  : { backgroundColor: "background.default" }
              }
            >
              <Badge color="error" variant={hasNotifications ? "dot" : ""}>
                <NotificationsNoneIcon
                  sx={
                    ownerId === 1 &&
                    location.pathname === "/counterfeit/notifications"
                      ? { color: "primaryOwner.contrastText" }
                      : { color: "neutral.dark" }
                  }
                />
              </Badge>
            </IconButton>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Topbar;
